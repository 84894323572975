import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input} from 'antd';
import {FormInstance} from 'rc-field-form/lib/interface';

import {useGetAccountInfo} from '../../../../queries/useGetAccountInfo';
import {EditProps} from '../EditItem';

const EditNumber = ({value, edit}: EditProps) => {
	const {t} = useTranslation();

	const {data} = useGetAccountInfo();
	const {mileageTypes} = data;

	const updateTotalMileageSum = useCallback((name: string, value: number, form: FormInstance) => {
		const type = name.includes('mileageDistance') ? 'distance' : name.includes('mileageCategory') ? 'category' : null;
		if (type !== null) {
			const [prefix, expenseId] = name.split('-');
			if (expenseId) {
				const distance = type === 'distance' ? value : Number(form.getFieldValue(`${prefix}-${expenseId}-mileageDistance`));
				const category = type === 'category' ? value : form.getFieldValue(`${prefix}-${expenseId}-mileageCategory`);
				const mileage = mileageTypes.find((mileage) => mileage.mileageId === category);
				if (!mileage) return;
				const total = (distance * mileage.mileageAmount).toFixed(2);
				form.setFieldValue(`${prefix}-${expenseId}-sum`, total);
			}
		}
	}, [mileageTypes]);

	const validateNumber = useCallback((
		name: string,
		value: string,
		form: FormInstance,
	) => {
		if (value === undefined) return Promise.resolve();
		const val = value.replace(/,/g, '.');
		if (isNaN(Number(val)) || Number(val) === 0) return Promise.reject(new Error(t('bill.view.edit-errors.field-not-valid')));
		const rounded = Number(val).toFixed(2);
		form.setFieldValue(edit.name, rounded);
		updateTotalMileageSum(name, Number(rounded), form);
		return Promise.resolve();
	}, [updateTotalMileageSum, edit, t]);

	return (
		<Form.Item
			name={edit.name}
			initialValue={edit.initialValue !== undefined ? edit.initialValue : value}
			className={'viewbill__steps__formitem'}
			rules={[
				{required: true},
				(form) => ({
					validator: (_, value) => validateNumber(edit.name, value, form),
				}),
			]}
		>
			<Input placeholder={edit.placeholder} addonAfter={edit.type === 'amount' ? '€' : 'km'} disabled={edit.disabled} />
		</Form.Item>
	);
};

export default EditNumber;
import moment from 'moment';

const zerofill = (num: string, length: number): string => {
	let output = num;
	while (output.length < length) {
		output = '0' + output;
	}
	return output;
};

type Options = {
	iban: string;
	sum: number;
	reference: string;
};

export const getVirtualBarcode = (options: Options): string | false => {
	const {iban, sum, reference} = options;

	if (!iban || !iban.includes('FI')) return false;

	const barcodeIban = iban.replace(/FI| /g, '');

	if (barcodeIban.length !== 16) return false;
	if (isNaN(sum) || sum > 999999.99) return false;

	const int = Math.floor(sum).toString();
	const barcodeInt = zerofill(int, 6);
	const decimals = Math.round((sum % 1) * 100).toString();
	const barcodeDecimals = zerofill(decimals, 2);

	const barcodeRef = zerofill(reference.replace(/ /g, ''), 20);

	const barcode = [
		'4',
		barcodeIban,
		barcodeInt,
		barcodeDecimals,
		'000',
		barcodeRef,
		moment().format('YYMMDD'),
	].join('');

	return barcode;
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import {config} from '@fortawesome/fontawesome-svg-core';
import * as Sentry from '@sentry/react';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/reset.css';

import './styles/index.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

// DayJS config
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

// Init localizations
import './i18n';

// Fontawesome config
config.autoAddCss = false;

// Init Sentry config
Sentry.init({
	dsn: 'https://5dbe538a048d0fc5957024abb49ceefb@o4506144612614144.ingest.sentry.io/4506144614187008',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/hallinta\.kululaskut\.fi\/api/],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
	<App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, DatePicker} from 'antd';
import {RuleObject} from 'antd/es/form';
import dayjs from 'dayjs';

import 'dayjs/locale/fi';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/sv';
import localeFI from 'antd/es/date-picker/locale/fi_FI';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeSV from 'antd/es/date-picker/locale/sv_SE';

import {EditProps} from '../EditItem';

type Props = EditProps & {
	rules: RuleObject[]
}

const EditDate = ({edit, rules}: Props) => {
	const {i18n} = useTranslation();

	const datePickerLocale = useMemo(() => {
		const lng = i18n.language;
		if (lng.includes('en')) return localeEN;
		if (lng.includes('sv')) return localeSV;
		return localeFI;
	}, [i18n]);

	const initialValue = useMemo(() => {
		if (edit.initialValue) {
			return dayjs(edit.initialValue, 'DD.MM.YYYY');
		}
		return undefined;
	}, [edit.initialValue]);

	return (
		<Form.Item
			name={edit.name}
			initialValue={initialValue}
			className={'viewbill__steps__formitem'}
			rules={rules}
		>
			<DatePicker
				locale={datePickerLocale}
				format={'D.M.YYYY'}
				allowClear={false}
			/>
		</Form.Item>
	);
};

export default EditDate;
import React from 'react';
import {ConfigProvider} from 'antd';
import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';

import Router from './Router';
import antdConfig from './styles/antd.config';

const queryClient = new QueryClient();

const App = () => {
	return (
		<ConfigProvider theme={{token: antdConfig}}>
			<QueryClientProvider client={queryClient}>
				<Router />
			</QueryClientProvider>
		</ConfigProvider>
	);
};

export default App;

import React, {useEffect} from 'react';
import {message} from 'antd';

import {useMessage} from '../../utils/useMessage';

const Message = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const {options} = useMessage();

	useEffect(() => {
		if (options !== null) {
			messageApi.open(options);
		}
	}, [options, messageApi]);

	return (
		<>
			{contextHolder}
		</>
	);
};

export default Message;
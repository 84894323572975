export const refNum = (id: number): string => {
	const x = id.toString();
	if (x.length > 19) {
		return '0';
	}

	const weights = [7, 3, 1, 7, 3, 1, 7, 3, 1, 7, 3, 1, 7, 3, 1, 7, 3, 1, 7];

	let checknum = 0;
	let e = 0;

	for (let i = x.length - 1; i > -1; i--) {
		checknum = checknum + weights[e++] * parseInt(x.charAt(i));
	}

	const a = (Math.ceil(checknum / 10) * 10 - checknum).toString();

	return x + a;
};
import {useState, useEffect} from 'react';

export const windowBreakpoints = {
	small: 640,
	medium: 900,
	large: 1800,
};

type WindowDimensions = {
    width: number;
    height: number;
};

const getWindowDimensions = (): WindowDimensions => {
	const {innerWidth: width, innerHeight: height} = window;
	return {
		width,
		height
	};
};

export const useWindowDimensions = (): WindowDimensions => {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
};
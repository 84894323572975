import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import moment from 'moment';

import {InvoiceContent, ExpenseType, InvoiceRow, InvoiceValues, Rule, Edit} from './types';
import {Bill, Expense} from '../../queries/useGetBillById';
import {MileageTypesData, useGetAccountInfo} from '../../queries/useGetAccountInfo';
import {formatIban} from '../../utils/formatIban';
import {formatNumber} from '../../utils/formatNumber';

const getBillEditLink = (data: Bill, accountSlug: string, t: TFunction): InvoiceRow[] => {
	if (data.billStatus === 'edit' && data.billEditHash) {
		return [{label: t('bill.view.details.edit-link'), values: {value: `http://${accountSlug}.kululaskut.fi/edit/${data.billId}/${data.billEditHash}`, type: 'link'}}];
	}
	return [];
};

const getArchiveAndPaidDates = (data: Bill, t: TFunction): InvoiceRow[] => {
	const res = [];
	if (data.billArchivedTimestamp && data.billArchivedUserName) {
		res.push({
			label: t('bill.view.details.accepted-date'),
			values: {value: `${moment(data.billArchivedTimestamp).format('D.M.YYYY')} / ${data.billArchivedUserName}`},
		});
	}

	if (data.billPaidTimestamp && data.billPaidUserName) {
		res.push({
			label: t('bill.view.details.mark-as-paid-date'),
			values: {
				value: `${moment(data.billPaidTimestamp).format('D.M.YYYY')} / ${data.billPaidUserName}`,
				edit: {
					name: 'billPaidTimestamp',
					initialValue: data.billPaidTimestamp,
					type: 'date' as Edit['type']
				},
			},
		});
	}

	return res;
};

type GeneralDetailOptions = {
	accountSlug: string;
	accountCollectIdentities: number;
}

const generateGeneralDetails = (data: Bill, {accountSlug, accountCollectIdentities}: GeneralDetailOptions, t: TFunction): InvoiceContent => {
	const hasMileageInvoices = !!data.expenses.find((expense) => expense.expenseMileage);
	return {
		title: t('bill.view.details.title'),
		status: 'info',
		type: 'general',
		id: 0,
		rows: [
			{label: t('bill.view.details.number'), values: {value: data.billRefnum || data.billId}},
			{label: t('bill.view.details.status'), values: {value: data.billStatus ? t(`bill.view.status.${data.billStatus}`) : ''}},
			...getBillEditLink(data, accountSlug, t),
			{label: t('bill.view.details.date'), values: {value: moment(data.billTimestamp).format('D.M.YYYY')}},
			{label: t('bill.view.details.total-sum'), values: {value: formatNumber(data.billSum, '€')}},
			{label: t('bill.view.details.creator-information'), values: [
				{
					value: data.billCreatorName,
					edit: {
						name: 'billCreatorName',
						placeholder: t('bill.view.details.edit-name'),
						rules: ['required'],
					},
				},
				...(accountCollectIdentities === 1 && hasMileageInvoices ? [{
					value: data.billCreatorIdentity,
					edit: {
						name: 'billCreatorIdentity',
						placeholder: t('bill.view.details.edit-identity'),
						rules: ['required'] as Rule[],
					},
				}] : []),
				{
					value: data.billCreatorEmail,
					link: `mailto:${data.billCreatorEmail}`,
					type: 'link',
					edit: {
						name: 'billCreatorEmail',
						placeholder: t('bill.view.details.edit-email'),
						rules: ['required', 'email'],
					},
				},
				{
					value: t('bill.view.details.phone', {phone: data.billCreatorPhone}),
					link: `tel:${data.billCreatorPhone}`,
					type: 'link',
					edit: {
						name: 'billCreatorPhone',
						initialValue: data.billCreatorPhone,
						placeholder: t('bill.view.details.edit-phone'),
						rules: ['required', 'phone'],
					},
				},
				{
					value: formatIban(data.billCreatorIban),
					edit: {
						name: 'billCreatorIban',
						placeholder: t('bill.view.details.edit-iban'),
						rules: ['required', 'iban'],
					},
				},
			]},
			{
				label: t('bill.view.details.accounting-num'),
				values: {
					value: data.billAccountingNum || '-' ,
					edit: {name: 'billAccountingNum', initialValue: data.billAccountingNum || '', placeholder: t('bill.view.details.accounting-num')},
				},
			},
			...getArchiveAndPaidDates(data, t),
		],
	};
};

const getExpenseType = (expense: Expense): ExpenseType => {
	if (expense.expenseMileageType) return 'mileage';
	if (expense.expenseAmount > 0) return 'invoice';
	return 'income';
};

const getTitle = (type: ExpenseType, t: TFunction): string => {
	if (type === 'mileage') return t('bill.view.invoice.title-mileage');
	if (type === 'invoice') return t('bill.view.invoice.title-expense');
	return t('bill.view.invoice.title-income');
};

const getRows = (expense: Expense, type: ExpenseType, mileageTypes: MileageTypesData[], t: TFunction): InvoiceRow[] => {
	// Basic rows
	const rows: InvoiceRow[] = [
		{values: {
			value: expense.expenseDescription,
			edit: {
				name: `expense-${expense.expenseId}-description`,
				type: 'textarea',
				placeholder: t('bill.view.invoice.edit-description'),
				rules: ['required'],
			},
		}},
		{
			label: t('bill.view.invoice.category'),
			values: {value: expense.expenseCategory, edit: {name: `expense-${expense.expenseId}-category`}}
		},
		{
			label: t('bill.view.invoice.sum'),
			values: {value: expense.expenseAmount || 0, type: 'amount', edit: {name: `expense-${expense.expenseId}-sum`, type: 'amount', initialValue: (expense.expenseAmount || 0).toFixed(2), disabled: type === 'mileage'}}
		},
	];

	// File related rows
	const files: InvoiceValues[] = expense.files.sort((a) => a.format === 'pdf' ? -1 : 1).map((file, index) => {
		return {
			value: t('bill.view.invoice.file', {num: index + 1, type: file.format}),
			link: file.url,
			type: `file/${file.format === 'pdf' ? 'pdf' : 'image'}`,
			edit: {
				name: `expense-${expense.expenseId}-file-${file.name}`,
				initialValue: file.id,
			},
		};
	});
	const fileRow: InvoiceRow[] = files.length > 0 ? [
		{
			label: t('bill.view.invoice.files'),
			values: files,
			fileEdit: true,
		}
	] : [];

	// Mileage related rows
	if (type === 'mileage') {
		const mileageType = mileageTypes.find((mileage) => {
			if (mileage.mileageId === Number(expense.expenseMileageType)) {
				return true;
			}
			return false;
		});
		const mileageCategory = mileageType ? `${mileageType.mileageType} (${formatNumber(mileageType.mileageAmount, '€')}/km)` : '';
		const mileageRows: InvoiceRow[] = [
			{
				label: t('bill.view.invoice.mileage-category'),
				values: {
					value: mileageCategory,
					edit: {name: `expense-${expense.expenseId}-mileageCategory`, type: 'mileageCategory', initialValue: mileageType?.mileageId},
				},
			},
			{
				label: t('bill.view.invoice.mileage-distance'),
				values: {
					value: formatNumber(expense.expenseMileage || 0, 'km'),
					edit: {name: `expense-${expense.expenseId}-mileageDistance`, type: 'distance', initialValue: (expense.expenseMileage || 0).toFixed(2)},
				},
			},
		];
		return [...rows, ...mileageRows, ...fileRow];
	}

	return [...rows, ...fileRow];
};

const generateExpenseDetails = (expense: Expense, mileageTypes: MileageTypesData[], t: TFunction): InvoiceContent => {
	const type = getExpenseType(expense);
	return {
		title: getTitle(type, t),
		status: type === 'income' ? 'income' : 'invoice',
		rows: getRows(expense, type, mileageTypes, t),
		type,
		id: expense.expenseId,
	};
};

type GenerateExpenses = {
	id: number;
	content: InvoiceContent[];
};

export const useGenerateExpenses = (bill: Bill): GenerateExpenses => {
	const {t} = useTranslation();

	const {data: accountInfo} = useGetAccountInfo();
	const {accountSlug, mileageTypes, accountCollectIdentities} = accountInfo;

	const generalDetails = useMemo(() => {
		return generateGeneralDetails(bill, {accountSlug, accountCollectIdentities}, t);
	}, [bill, accountSlug, accountCollectIdentities, t]);

	const expenseDetails = useMemo(() => {
		return bill.expenses.map((expense) => (
			generateExpenseDetails(expense, mileageTypes, t)
		)).sort((a) => a.status === 'income' ? -1 : 1);
	}, [bill, t, mileageTypes]);

	if (!bill.billId) {
		return {
			id: 0,
			content: [],
		};
	}

	return {
		id: bill.billId,
		content: [
			generalDetails,
			...expenseDetails,
		],
	};
};
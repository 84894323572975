import React from 'react';
import {Form, Input} from 'antd';
import {RuleObject} from 'antd/es/form';

import {EditProps} from '../EditItem';

const {TextArea} = Input;

type Props = EditProps & {
	rules: RuleObject[]
}

const EditTextArea = ({value, edit, rules}: Props) => {
	return (
		<Form.Item
			name={edit.name}
			initialValue={edit.initialValue !== undefined ? edit.initialValue : value}
			className={'viewbill__steps__formitem'}
			rules={rules}
		>
			<TextArea placeholder={edit.placeholder} />
		</Form.Item>
	);
};

export default EditTextArea;
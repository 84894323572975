import React, {useCallback, useMemo} from 'react';
import {Form, Select} from 'antd';
import {FormInstance} from 'rc-field-form/lib/interface';

import {useGetAccountInfo} from '../../../../queries/useGetAccountInfo';
import {formatNumber} from '../../../../utils/formatNumber';

import {EditProps} from '../EditItem';

type Props = {
	edit: EditProps['edit'];
};

const EditMileageCategory = ({edit}: Props) => {
	const {data} = useGetAccountInfo();
	const {mileageTypes} = data;

	const mileageOptions = useMemo(() => {
		if (edit.type !== 'mileageCategory') return [];
		return mileageTypes.map((mileage) => ({
			value: mileage.mileageId,
			label: `${mileage.mileageType} (${formatNumber(mileage.mileageAmount, '€')}€/km)`,
		}));
	}, [mileageTypes, edit]);

	const updateTotalMileageSum = useCallback((name: string, value: number, form: FormInstance) => {
		const type = name.includes('mileageDistance') ? 'distance' : name.includes('mileageCategory') ? 'category' : null;
		if (type !== null) {
			const [prefix, expenseId] = name.split('-');
			if (expenseId) {
				const distance = type === 'distance' ? value : Number(form.getFieldValue(`${prefix}-${expenseId}-mileageDistance`));
				const category = type === 'category' ? value : form.getFieldValue(`${prefix}-${expenseId}-mileageCategory`);
				const mileage = mileageTypes.find((mileage) => mileage.mileageId === category);
				if (!mileage) return;
				const total = (distance * mileage.mileageAmount).toFixed(2);
				form.setFieldValue(`${prefix}-${expenseId}-sum`, total);
			}
		}
	}, [mileageTypes]);

	const validateMileageCategory = useCallback((
		name: string,
		value: number,
		form: FormInstance,
	) => {
		updateTotalMileageSum(name, value, form);
		return Promise.resolve();
	}, [updateTotalMileageSum]);

	return (
		<Form.Item
			name={edit.name}
			className={'viewbill__steps__formitem'}
			required={false}
			initialValue={edit.initialValue}
			validateTrigger={'onChange'}
			rules={[
				{required: true},
				(form) => ({
					validator: (_, value) => validateMileageCategory(edit.name, value, form),
				}),
			]}
		>
			<Select
				popupMatchSelectWidth={false}
				options={mileageOptions}
			/>
		</Form.Item>
	);
};

export default EditMileageCategory;
import React, {useMemo} from 'react';
import {RuleObject} from 'antd/es/form';
import validator from 'validator';
import i18n from '../../../i18n';

import {Edit, Rule} from '../types';

import EditInput from './EditFields/EditInput';
import EditTextArea from './EditFields/EditTextArea';
import EditNumber from './EditFields/EditNumber';
import EditMileageCategory from './EditFields/EditMileageCategory';
import EditDate from './EditFields/EditDate';

export type EditProps = {
	value: string | number;
	edit: Edit;
};

const getRule = (rule: Rule): RuleObject => {
	if (rule === 'required') return {required: true};
	if (rule === 'email') {
		return {
			validator: (_, value) => {
				if (value && !validator.isEmail(value as string)) {
					return Promise.reject(i18n.t('bill.view.edit-errors.email-not-valid'));
				} else {
					return Promise.resolve();
				}
			},
		};
	}
	if (rule === 'phone') {
		return {
			validator: (_, value) => {
				if (value && !validator.isMobilePhone(value as string)) {
					return Promise.reject(i18n.t('bill.view.edit-errors.phone-not-valid'));
				} else {
					return Promise.resolve();
				}
			},
		};
	}
	if (rule === 'iban') {
		return {
			validator: (_, value) => {
				if (value && !validator.isIBAN(value as string)) {
					return Promise.reject(i18n.t('bill.view.edit-errors.iban-not-valid'));
				} else {
					return Promise.resolve();
				}
			},
		};
	}
	return {};
};

const EditItem = ({value, edit}: EditProps) => {
	const rules = useMemo(() => {
		if (!edit.rules || edit.rules.length === 0) return [];
		return edit.rules.map(getRule);
	}, [edit]);

	if (edit.type === 'textarea') {
		return (
			<EditTextArea
				edit={edit}
				value={value}
				rules={rules}
			/>
		);
	}

	if (edit.type === 'distance' || edit.type === 'amount') {
		return (
			<EditNumber
				edit={edit}
				value={value}
			/>
		);
	}

	if (edit.type === 'mileageCategory') {
		return (
			<EditMileageCategory edit={edit} />
		);
	}

	if (edit.type === 'date') {
		return (
			<EditDate
				edit={edit}
				value={value}
				rules={rules}
			/>
		);
	}

	return (
		<EditInput
			edit={edit}
			value={value}
			rules={rules}
		/>
	);
};

export default EditItem;